<template>
<div class="grid-container">
    <div class="logo">
      <img src="@/assets/icons/logo.png" />
    </div>
    <div class="container">
        <div class="alert" v-if="errorMsg.length > 0">
            <h1>Alert</h1>
            <h3>{{ errorMsg }}</h3>
        </div>
        <form @submit.prevent="handleSubmit" class="form shadow">
            <p class="instruction">Enter the email address associated with your account and we'll send you a link to reset your password.</p>

            <input
                type="email"
                id="reset"
                placeholder="E-mail"
                v-model="model.email"
                required
            />

            <input type="submit" value="Continue" />
        </form>

        <router-link to="/sign-in" class="register-link">
            Not a member yet? Click here to register.
        </router-link>
    </div>
</div>
</template>

<script>
export default {
    name: "ForgotPassword",
    data() {
        return {
            model: {
                email: ""
            },
            errorMsg: ""
        }
    },
    methods: {
        async handleSubmit(e) {
            if(this.validateEmailAndSetErrorMsg()) {
                // We couldn't find that email. Please try again.
                const res = await this.$api.forgoten(this.model);
                if(res.success) {
                    // In case of success redirect.
                    this.errorMsg = "Link for reseting your password was sent to your email."
                } else {
                    this.errorMsg = res.msg;
                }
            }
        },
        validateEmailAndSetErrorMsg() {
            const trimedEmail = this.model.email.trim();
            const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            if (typeof trimedEmail !== 'string') return false;
            if (trimedEmail.length < 8 || trimedEmail.length > 255) {
                this.errorMsg = "E-mail length must be between 8 and 255 characters.";
                return false
            };
            if (!trimedEmail.trim().length) {
                this.errorMsg = "E-mail is a required field.";
                return false
            };

            if (!(re.test(String(trimedEmail).toLowerCase()))) {
                this.errorMsg = "Please enter the correct e-mail address format.";
                return false
            }

            this.errorMsg = "";
            return true;
        }, 
    }   
}
</script>

<style lang="scss" scoped>
    .grid-container {
        @include gradient;

        display: grid;
        grid-template-columns: 0.9fr 1.2fr 0.9fr;
        grid-template-rows: auto 15% auto 30%;
        gap: 0px 0px;
        grid-template-areas:
            ". . ."
            ". logo ."
            ". fPassword ."
            ". . .";

        @include center;
        text-align: center;
        height: 100vh;

        @include respond-to("medium") {
            grid-template-columns: 0.1fr auto 0.1fr;
            gap: 20px 20px;
        }
    }

    .logo {
        display: flex;
        align-items: flex-end;

        grid-area: logo;
        @include center;

        img {
            width: 200px;
            @include white-filter;
        }
    }

    .container {
        width: 100%;
        height: 100%;
        max-width: 500px;
        grid-area: fPassword;
        @include center;

        .register-link {
            text-decoration: none;
            color: #fff;
        }

        .form {
            padding: 5%;
            margin-bottom: 15px;

            @include box-shadow;
            background-color: #242326;
            border: none;
            border-radius: 15px;

            .instruction {
                color: #fff;
                padding: 0 15px 15px 15px;
                text-align: left;
            }

            .forgot-password {
                margin-top: 10px;
                padding-bottom: 0 !important;
                text-decoration: none;
                font-weight: 500;

                &:hover {
                    color: var(--orange);
                    font-weight: 600;
                }
            }

            // Inputs
            input {
                display: block;
                box-sizing: content-box;
                width: 90%;
                max-width: 90%;
                @include center;
                margin-top: 15px;

                border: none;
                border-radius: 15px;
                background-color: #f1f1fe;
                color: #090909;

                @include placeholder {
                    color: #8b8a92;
                    font-size: 1.1em;
                }
                animation: fadeIn 0.3s, popup 0.4s;
            }
                
            input[type="email"] {
                padding: 15px;

                &:focus {
                    background-color: #f1f1fe;
                }
            }

            input[type="submit"] {
                @extend .pointer;
                @extend .noselect;
                @include gradient;

                width: 30%;
                min-width: 6em;
                padding: 15px;

                color: #ffffff;
                font-size: 1em;

                &:hover {
                    @include popup(1.04);
                }
            }
        }

        .alert {
            @include center;
            @include box-shadow;

            animation: fadeIn 0.7s, popup 0.7s, rotate 0.4s;

            position: fixed;
            top: 10%;
            right: 10%;

            background-color: var(--orange);
            border-radius: 15px;
            padding: 10px;

            h1 {
            color: #242326;
            font-size: 1.5em;
            }
            h3 {
            color: #242326;
            font-size: 1.2em;
            }
        }
  }
</style>